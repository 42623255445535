.logo{
    width: 150px;
}
.navbar{
    background-color: $beige;
    color: $noir;
    padding-right: 18px;
    position: sticky;
    top: 0;
    z-index: 10;
    .subtitle-logo{
        color: $noir;
        font-size: 14px;
        margin-left: 10px;
        font-weight: 600;
    }
    .nav-link{
        font-weight: 600;
    }
    .nav-item{
        button.nav-link,
        a.nav-link{
            color: $noir;
            i{
                font-size: 22px;
            }
            &#deconexion{
                
            }
            &:hover{
                color: $rouge;
            }
        }

    }
    &-nav{    
        flex-direction: row;
        align-items: center;
        margin-bottom: 0!important;
        li{
            margin-left: 14px;
        }
       
    }
}