
/* Ajouts pour l'accessibilité */
.skip-link {
    position: absolute;
    top: -40px;
    left: 0;
    background: #000;
    color: white;
    padding: 8px;
    z-index: 100;
    transition: top 0.3s;
}

.skip-link:focus {
    top: 0;
}

/* Mode contraste élevé */
.high-contrast {
    --primary-color: #0000ff;
    --text-color: #000000;
    --background-color: #ffffff;
}

.high-contrast .card {
    border: 2px solid #000;
}

/* Focus visible */
*:focus {
    outline: 3px solid #0066cc;
    outline-offset: 2px;
}

/* Taille de texte minimum pour la lisibilité */
body {
    font-size: 16px;
    line-height: 1.5;
}

/* Contraste des boutons */
.btn-primary {
    background-color: #0066cc;
    border-color: #0066cc;
    color: #ffffff;
}

/* Messages d'erreur */
.invalid-feedback {
    color: #dc3545;
    font-weight: bold;
}

.card-details{
    dl{
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-bottom: 0;
        align-items: center;
    }
}