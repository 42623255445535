.password-reset,
.login-form,.connextion{
    max-width: 90%;
    width: 450px;
}
.password-reset,
.login-form,
.connection{
    .logo{
        max-width: 300px;
        margin: 0 auto;
        margin-top: 20px;
    }
}
.password-reset{
    #password{
        margin-bottom: 0;
    }
}