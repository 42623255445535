.card-books{
    display: flex;
    flex-wrap: wrap;
    padding-top: 24px;
    .card-container{
      width: 100%;
      min-width: 100%;
      .card{
        padding: 12px;
        margin-bottom: 12px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        background-color: #eee;
        border-color: #eee;
        color: $charcoal;
        .container-image{
          width: 50px;
          height: 50px;
          overflow: hidden;
          border-radius: 100px;
          
          img{
                /* width: auto; */
            min-height: 100%;
            min-width: 100%;
          }
        }
        .card-body{
            text-align: left;
            padding: 0;
            padding-left: 24px;
            display: flex;
            
            @media screen and (max-width: 990px) {    align-items: flex-start;}
            @media screen and (min-width: 990px) {    align-items: center;}
           
          

            p{
                padding-bottom: 0;
                margin-bottom: 0;
            }
            h3{
                white-space: nowrap;
                margin-bottom: 0;
                font-size: 1.25rem;
            }
            .card-details,
            .card-details dl{
                
                display: flex;
                justify-content: space-around;
                width: 100%;
                @media screen and (min-width: 990px) {   margin-left: 24px;}
                @media screen and (max-width: 990px) { 
                    flex-direction: column;
                    align-items: flex-start;
                }
                

            }
            @media screen and (max-width: 990px) {   
                flex-direction: column;
            }
          
        }
        .actions{
            .btn{
                background-color: #fff;
                border-color: #fff;
                color: $charcoal;
                border-radius: 100px;
                svg,i{
                    margin-left: 8px;
                }
            }
            .text-btn{
                background-color: rgba(255, 255, 255, .4);
                border-radius: 100px;
                padding: 12px;
                padding-top: 8px;
                padding-bottom: 8px;
                margin-bottom: 0px;
                svg,i{
                    margin-left: 8px;
                }
                button.btn-inner-red{
                    margin-left: 8px;
                    background-color: transparent;
                    border: none;

                    color: #e76f51;
                    padding: 0;
                    svg,i{
                        margin-left: 0px;
                        line-height: 0;
                        padding: 6px;
                    }
                }
            }
          }
      }
    }
}
.card-series{
    padding-top: 24px;
    display: flex;
    flex-wrap: wrap;
    .card-container{
        max-width: 33.33%;
        padding: 12px;
        &.deja-lu {
            opacity: 0.7;
            .card {
                background-color: #f8f9fa;
                &::after {
                    content: "Déjà lu";
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    background-color: rgba(0,0,0,0.7);
                    color: white;
                    padding: 5px 10px;
                    border-radius: 15px;
                    font-size: 0.8em;
                }
            }
        }
    }
    .container-image button{
        height: 300px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    
    }
    .card-details{
        text-align: left;
        p{
            margin-bottom: 0;
        }
    }
}


.card-container.deja-lu {
    position: relative;
}

.card-container.deja-lu .card {
    opacity: 0.5;
    background-color: #f8f9fa;
}

.card-container.deja-lu::after {
    content: "Déjà lu";
    position: absolute;
    top: -13px;
    right: 10px;
    background-color: #198754;
    color: white;
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 0.8em;
    z-index: 1;
}
.card-books .card dd{
    display: flex;
    margin-bottom: 0;
}

.bibliothecaire-badge {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: 12px;
    position: relative;

    
    
    

    &:hover {
        .badge-stars {
            i.bi-star-fill {
                animation: glowing 1.5s infinite;
                animation-delay: calc(var(--i) * 0.1s);
            }
        }
    }
}

@keyframes glowing {
    0% {
        transform: scale(1);
        filter: brightness(100%);
    }
    50% {
        transform: scale(1.2);
        filter: brightness(130%);
    }
    100% {
        transform: scale(1);
        filter: brightness(100%);
    }
}

// Style pour afficher le niveau dans les modales ou autres endroits
.bibliothecaire-niveau {
    display: inline-flex;
    align-items: center;
    background: linear-gradient(45deg, $beige, $beige2);
    border: 1px solid $noir;
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 0.9em;
    gap: 8px;

    .badge-stars {
        display: flex;
        gap: 2px;
    }
}