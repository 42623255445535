body{
  overflow-x: hidden;
}
img{
  max-width: 100%;
}
.modal p{
  font-weight: 400;
}
.wrappe_img{
background-color: $beige;
  min-height: 350px;
  position: relative;

  display: flex;
  align-content: center;
  vertical-align: middle;
  flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: hidden;

  .container{
    color: $noir;
    position: relative;
    text-align: left;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.logobg{
  opacity: .1;
  width: 99%;
  position: absolute;
  z-index: 0;
}

.modal-backdrop{
  z-index: 10;
}
.modal{
  z-index: 11;
}
/* cards */


/* buttons */
.btn-round{
  border-radius: 100px;
  padding: 12px;
  
}
.btn-wht{
  background-color: #fff;
  color: $noir;
  font-weight: 600;
  &:hover{
    background-color: $rouge;
    color: #fff;
    font-weight: 600;
  }
}




.pitch{
  max-width: 900px;
  margin: 0 auto;
  padding-bottom: 30px;
  font-weight: 600;
}


.wrappe_img .container.a_propos{
  text-align: center;
}

.modal-header{
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}
.modal-body{
  padding-left: 24px;
  padding-right: 24px;
}
.modal-footer{
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
}


.modal#editusermodal .form-check .form-check-input {
  margin-top: 17px;
}
.upload_img{
  width:100px;
  max-height:100px;
}