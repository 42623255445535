/* head */
$bgAC : #fff;
$fontsAC:$noir;
body,html{
    color: var(--bs-dark);
  }
  
  
  /* Optional nicer scrollbars */
  .autocomplete-menu {
    mark {
      text-decoration: underline;
      background: none;
      color: currentColor;
      padding: 0;
    }

  

    &::-webkit-scrollbar {
      width: 8px;
      &-track {
        background: var(--scroller-background);
      }
      &-thumb {
        background: var(--scroller-thumb);
        &:hover {
          background: var(--scroller-thumb-hover);
        }
      }
    }
  }

  .autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;
    &-items {
      position: absolute;
      border: none;
      border-bottom: none;
      border-top: none;
      z-index: 99;
      top: 80px;
      left: 0;
      right: auto;
    }
    .form-control{
        border-radius: 0;
    }
  }
    


  .search-serie{
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border:1px solid $noir;

    background-color: $bgAC;
    border-radius: 0px;
    padding:20px;
  
    .search,.reset{
      min-width: 41px;
      max-width: 41px;
      padding: 0;
      font-size: 18px;
      position: absolute;
      svg,i{
        font-size: 18px;
        color: $fontsAC;
        transform: translateY(2px);
        display: block;
      }
    }
    .search{
      left: 20px;
    }
    .reset{
      right: 0;
    }
    .search{
      svg,i{
       opacity: .4;
       
      }
    }
    .autocomplete {
      width: 100%;
      max-width: 100%;
      margin-left: 12px;
      margin-right: 12px;
      input{
        width: 100%;
        background-color: transparent;
        border-top: none;
        border-left: none;
        border-right: none;
        border-color: transparent;
        color: $fontsAC;
        line-height: 33px;
        padding-left: 30px;
        padding-right: 30px;
        &::placeholder {
          color: rgba($color: $fontsAC, $alpha: .8);
          opacity: .8; /* Firefox */
        }
        
        &::-ms-input-placeholder { /* Edge 12 -18 */
          color: rgba($color: $fontsAC, $alpha: .8);
        }
        &:focus-visible {
          outline: none
        }
        &:-internal-autofill-selected {
          appearance: none;
          background-image: none !important;
          background-color: transparent !important;
          color: $fontsAC !important;
        }
      }
    }
    .autocomplete-items{
      border-radius: 12px;
      border-radius: 0;
      display: flex;
      &>div{
          
          color: $noir;
          padding: 8px;
          cursor: pointer;
          min-width: max-content;

          background-color: #fff;
          border: 7px solid $noir;
    margin-right: 8px;
      }
    }
  
  }
  .search-block{
    background-color: $noir;
    padding: 0px;
    z-index: 1;
    position: relative;
    position: sticky;
    top: 78px;
    
  }