// Nouvelles variables pour le néo-brutalisme
$neo-shadow-offset: 6px;
$neo-border-radius: 12px;
$neo-transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);

// Mixins pour les effets néo-brutalistes
@mixin neo-shadow($color: $noir) {
  box-shadow: $neo-shadow-offset $neo-shadow-offset 0 $color;
  transition: box-shadow $neo-transition, transform $neo-transition;
}

@mixin neo-hover {
  &:hover {
    transform: translate(-2px, -2px);
    box-shadow: ($neo-shadow-offset + 2) ($neo-shadow-offset + 2) 0 $noir;
  }
}

// Style de base pour le néo-brutalisme
.neo-brutal {
  // Typographie
  h1, h2, h3, h4, h5 {
    position: relative;
    font-size: clamp(1.5rem, 4vw, 3.5rem);
    line-height: 1;
    margin-bottom: 1.5rem;
    display: inline-flex;
    
    &::after {
      content: '';
      position: absolute;
      width: 30%;
      height: 8px;
      background: $rouge;
      bottom: -10px;
      left: 0;
    }
  }

  // Cartes repensées
  .card {
    background: $beige2;
    border: 2px solid $noir;
    border-radius: $neo-border-radius;
    overflow: hidden;
    @include neo-shadow;
    @include neo-hover;
    
    .container-image {
      position: relative;
      overflow: hidden;
      
      
      img {
        transform: scale(1);
        transition: transform 0.5s ease;
      }
      
      &:hover img {
        transform: scale(1.05);
      }
    }
    
    .card-body {
      padding: 1.5rem;
      
      h5 {
        font-size: 1.25rem;
        font-weight: 700;
        color: $noir;
      }
    }
  }

  // Boutons modernisés
  .btn {
    position: relative;
    border: 2px solid $noir;
    border-radius: $neo-border-radius;
    padding: 0.8rem 1.5rem;
    font-weight: 600;
    overflow: hidden;
    @include neo-shadow;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(45deg, rgba($rouge, 0.2), transparent);
      transform: translateX(-100%);
      transition: transform 0.3s ease;
    }
    
    &:hover {
      @include neo-hover;
      
      &::before {
        transform: translateX(0);
      }
    }
    
    &-primary {
      background: $persian-green;
      color: white;
    }
    
    &-secondary {
      background: $beige;
      color: $noir;
    }
  }

  // Navigation repensée
  .navbar {
    background: $beige2;
    border-bottom: 2px solid $noir;
    @include neo-shadow;
    
    .nav-link {
      position: relative;
      padding: 0;
      margin: 0 0.5rem;
      
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: $rouge;
        transform: scaleX(0);
        transition: transform 0.3s ease;
      }
      
      &:hover::after {
        transform: scaleX(1);
      }
    }
  }

  // Formulaires modernisés
  .form-control {
    border: 2px solid $noir;
    border-radius: $neo-border-radius;
    padding: 1rem;
    @include neo-shadow(rgba($noir, 0.1));
    
    &:focus {
      box-shadow: $neo-shadow-offset $neo-shadow-offset 0 rgba($persian-green, 0.3);
      border-color: $persian-green;
      transform: translate(-2px, -2px);
    }
  }

  // Modal repensée
  .modal-content {
    background: white;
    border: 2px solid $noir;
    border-radius: $neo-border-radius;
    overflow: hidden;
    @include neo-shadow;
    
    .modal-header {
      background: $beige2;
      border-bottom: 2px solid $noir;
    }
    
    .modal-footer {
      background: $beige2;
      border-top: 2px solid $noir;
    }
  }

  // Badges de confiance stylisés
  .trust-badge {
    border: 2px solid $noir;
    border-radius: $neo-border-radius;
    padding: 0.5rem 1rem;
    @include neo-shadow(rgba($noir, 0.1));
    
    &.trust-level-5 {
      background: linear-gradient(45deg, $persian-green, adjust-hue($persian-green, 20deg));
      color: white;
    }
  }

  // Animation pour les pages
  .main {
    animation: pageLoad 0.6s ease-out;
  }

  @keyframes pageLoad {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  // Section recherche modernisée
  .search-block {
    border: 2px solid $noir;
    border-radius: $neo-border-radius;
    @include neo-shadow;
    
    .search-serie {
      border-radius: $neo-border-radius;

      padding: 0;
      #reset-search{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
      }
      input {
        border: none;
       
        padding: 1rem 3rem;
        
        
      }
    }
  }

  // Grille de séries avec masonry
  .card-series {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem;
    
    .card-container {
      max-width: 100%;
      transform: translate(0);
      transition: transform 0.3s ease;
      
      &:hover {
        transform: translateY(-10px);
      }
    }
  }
}
