@media screen and (max-width: 550px) {

        .navbar-nav{
            flex-wrap: wrap;
            li{
                margin-left: 0;
            }
        }
    

    
}

@media screen and (max-width: 990px) {
    .card-books{
        .card-container .card .card-body{
            h3 {
                margin-bottom: 12px;
            }
        } 
    } 
    .serie-volumes{
        .btn-secondary{
            transform: translateY(-20px);
        }
    }
}
