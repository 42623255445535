/* fonts */
html,body{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
  h1,h2,h3,h5{
    font-family: "Lilita One", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .montserrat {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
  
  .lilita-one-regular {
    font-family: "Lilita One", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  