.btn{
    &-red{
        background-color: $rouge!important;
        border-color: $rouge!important;
        color: #fff!important;
    }
    &-green{
        background-color: $persian-green!important;
        border-color: $persian-green!important;
        color: #fff!important;
    }
}
