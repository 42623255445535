/* Mode contraste élevé */
.high-contrast {
    /* Couleurs de base */
    --text-color: #212121;
    --background-color: #ffffff;
    --link-color: #0000EE;
    --link-visited: #551A8B;
}

/* Application des styles spécifiques pour le mode contraste élevé */
.high-contrast body {
    background-color: var(--background-color);
    color: var(--text-color);
}

.high-contrast .navbar {
    background-color: #212121;
    color: #ffffff;
}

.high-contrast .card {
    background-color: var(--background-color);
    border: 2px solid #212121;
}

.high-contrast .btn-primary {
    background-color: #0071bc;
    color: #ffffff;
    border: 2px solid #212121;
}

.high-contrast .btn-secondary {
    background-color: #5b616b;
    color: #ffffff;
    border: 2px solid #212121;
}

.high-contrast .modal-content {
    background-color: var(--background-color);
    border: 3px solid #212121;
}

.high-contrast input,
.high-contrast select,
.high-contrast textarea {
    background-color: var(--background-color);
    border: 2px solid #212121;
    color: var(--text-color);
}

.high-contrast .form-control:focus {
    border-color: #0000EE;
    box-shadow: 0 0 0 0.25rem rgba(0, 0, 238, 0.5);
}

.high-contrast a {
    color: var(--link-color);
    text-decoration: underline;
}

.high-contrast a:visited {
    color: var(--link-visited);
}

.high-contrast .alert {
    border: 2px solid #212121;
}

.high-contrast .alert-info {
    background-color: #CCE5FF;
    color: #212121;
}

.high-contrast .alert-warning {
    background-color: #FFF3CD;
    color: #212121;
}

.high-contrast .alert-danger {
    background-color: #FFE6E6;
    color: #212121;
}

.high-contrast .badge {
    border: 1px solid #212121;
}

.high-contrast .table {
    border-collapse: collapse;
}

.high-contrast .table th,
.high-contrast .table td {
    border: 2px solid #212121;
}

.high-contrast .invalid-feedback {
    color: #CC0000;
    font-weight: bold;
}

/* Style du bouton de contraste */
.contrast-toggle {
    padding: 8px;
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.contrast-toggle:hover {
    background: rgba(255, 255, 255, 0.1);
}

.high-contrast .contrast-toggle {
    border-color: #ffffff;
    color: #ffffff;
}

/* Focus visible plus prononcé */
.high-contrast *:focus {
    outline: 3px solid #0000EE;
    outline-offset: 2px;
}

.high-contrast{
    .navbar{
        a.nav-link,
        button.nav-link,
        .subtitle-logo{
        color: #fff;
        }
        
    }
    a.btn-primary:visited,
    a.btn-secondary:visited {
    color: #ffffff; 
}

}
