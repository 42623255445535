.serie-stats {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.serie-stats .badge {
    font-size: 1rem;
    padding: 8px 16px;
}

.serie-info {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
}

.actions {
    display: flex;
    gap: 10px;
}

.serie-stats .badge {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    transition: all 0.2s;
}

.serie-stats .badge:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.serie-info {
    border: 1px solid rgba(0,0,0,0.1);
}

.actions .btn {
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

.serie-description {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #000;
    text-align: justify;
    margin-top: 24px;
}
.card-series{
    .btn-round{
        margin: 24px;
        margin-top: 0;
    }
}

.wrappe_series{
    background-color: $beige;
      min-height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
}

.badge-lecture {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 0.8em;
    z-index: 0;
    font-weight: 600;
}

.badge-reading {
    background-color: #0d6efd;
    color: white;
}

.badge-read {
    background-color: #198754;
    color: white;
}

.serie-legende {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 1rem;
}

.serie-legende .badge {
    font-size: 0.9em;
    padding: 8px 16px;
}

.serie-en-cours .card {
    border-color: #0d6efd;
    box-shadow: 0 0 0 1px #0d6efd;
}

.serie-lue .card {
    border-color: #198754;
    box-shadow: 0 0 0 1px #198754;
}