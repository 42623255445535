.erreur{
    min-height: 100vh;
    width: 100VW !important;
    background-image: url(../../assets/img/404.jpeg);
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: contain;

    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  .erreur-in{
    
  }
}
.connection{
    height: 100%;
    display: flex;
    align-items: center;
    width: 100VW !important;
    justify-content: center;
    background-image: url(../../assets/img/bg.jpg);
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    color:#fff;
    @media (min-height: 600px) {
      height: 100vh;
    }
    .form-check-label,p{
      color: #fff;
    }
    .form-signin {
      max-width: 330px;
      padding: 1rem;
    }
    h1{
      font-family: var(--bs-body-font-family);
      font-size: 1rem;
    }
    p
    .form-signin .form-floating:focus-within {
      z-index: 2;
    }
  
    .form-signin{
        input[type="email"] {
            margin-bottom: -1px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            
        }
        input[type="password"] {
            margin-bottom: 10px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    } 
    
  
    .form-floating{
      color: rgba(#333, .8);
    }
    input.form-control{
      border-radius: 0;
      margin-bottom: 12px;

    }

    .alert-info{
      background-color: #fff;
      p{
        color: $noir;
        font-weight: 600;
        margin-bottom: 0;
      }
    }
    .btn-link{
      background-color: #fff;
      margin-top: 12px;
    }
    .logo{    display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    p.text-danger{
      font-weight: 600;
    }
  }